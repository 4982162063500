<template>
	<div :style="buyBg">
		<Header :title="$t('setting.account')"></Header>
		
		<div class="column-center-content main-content">
			<div class="row-content absolute-content" style="width: 100%; height: 60px; max-width: 600px;">
				<router-link to="/changeLoginPwd" class="row-content shadow-box-content list-box-content">
					<i class="fa fa-lock" aria-hidden="true" style="margin: 0px 15px; color: #009245;"></i>
					<label class="gray-text-title">{{$t('setting.changeLoginPwd')}}</label>
					<i class="fa fa-chevron-right" aria-hidden="true" style="position: absolute; right: 50px; color: #009245;"></i>
				</router-link>
			</div>
			
			<div class="row-content absolute-content" style="width: 100%; height: 60px; top: 164px;  max-width: 600px;">
				<router-link to="/changePayPwd" class="row-content shadow-box-content list-box-content">
					<i class="fa fa-key" aria-hidden="true" style="margin: 0px 15px; color: #009245;"></i>
					<label class="gray-text-title">{{$t('setting.changePayPwd')}}</label>
					<i class="fa fa-chevron-right" aria-hidden="true" style="position: absolute; right: 50px; color: #009245;"></i>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../components/NavHeader.vue'
	
	export default {
		name: 'AccountSetting',
		components: {
			Header
		},
		data:() => ({
			buyBg : {
				height: "100vh",
				width: "100%",
				backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			}
		})
	}
</script>

<style>
</style>
